import React, { useState } from 'react';
import styled from 'styled-components';

const Header: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <HeaderContainer>
            <HeaderContent>
                <LogoContainer href="https://amazonfreedom.ru/">
                    <LogoText>AMAZON FREEDOM</LogoText>
                    <LogoImage
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f27a0af8f1726ac0ac17ad101443f7676c6139e802a29fc0570403f9a8c55ff?apiKey=35bf81d825094f66b687d157fe963e7b&"
                    />
                </LogoContainer>
                <NavLinks menuOpen={menuOpen}>
                    <NavLink href="https://amazonfreedom.ru/#about">Обо мне</NavLink>
                    <NavLink href="https://amazonfreedom.ru/#courses">Курсы</NavLink>
                    <NavLink href="https://amazonfreedom.ru/#forwho">Кому подойдет</NavLink>
                    <NavLink href="https://amazonfreedom.ru/#faq">FAQ</NavLink>
                </NavLinks>
                <BurgerMenu onClick={toggleMenu}>
                    <BurgerBar />
                    <BurgerBar />
                    <BurgerBar />
                </BurgerMenu>
                <ApplyButtonContainer href="https://amazonfreedom.ru/#courses">
                    <ApplyButton>Оставить заявку</ApplyButton>
                    <ApplyButtonIcon
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9497d9dd78f06ef12525fead37fd76fe82afbda7ad73f281a12d48f88aa3f98c?apiKey=35bf81d825094f66b687d157fe963e7b&"
                    />
                </ApplyButtonContainer>
            </HeaderContent>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.div`
    background-color: #001B4F;
    display: flex;
    width: 100%;
    justify-content: start;
    padding: 20px 0;
    box-sizing: border-box;
`;

const HeaderContent = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: #fff;
    font-weight: 400;
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

const LogoContainer = styled.a`
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    flex-direction: column;
    text-decoration: none;
`;

const LogoText = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    color: white;
`;

const LogoImage = styled.img`
    width: 32px;
    margin-top: 2px;
    margin-left: -45px;
`;

const NavLinks = styled.div<{ menuOpen: boolean }>`
    display: flex;
    gap: 20px;
    font-size: 14px;
    @media (max-width: 991px) {
        flex-direction: column;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: #001B4F;
        transition: max-height 0.3s ease-in-out;
        max-height: ${({ menuOpen }) => (menuOpen ? '300px' : '0')};
        overflow: hidden;
        padding: ${({ menuOpen }) => (menuOpen ? '10px 20px' : '0 20px')};
        box-sizing: border-box;
    }
`;

const NavLink = styled.a`
    font-family: 'Jost', sans-serif;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

const BurgerMenu = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    @media (max-width: 991px) {
        display: flex;
    }
`;

const BurgerBar = styled.div`
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
`;

const ApplyButtonContainer = styled.a`
    border-radius: 140px;
    background-color: #f90;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    text-decoration: none;
    @media (max-width: 991px) {
        display: none;
    }
`;

const ApplyButton = styled.div`
    font-family: 'Jost', sans-serif;
    color: white;
`;

const ApplyButtonIcon = styled.img`
    width: 20px;
`;

export default Header;
