// src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import GlobalStyle from './GlobalStyle';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import SetPassword from './pages/SetPassword';
import CourseDetail from './pages/CourseDetail';
import VideoPlayer from './pages/VideoPlayer';
import HomePage from './pages/HomePage';
import TermsPage from './pages/TermsPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PriceAnalyzer from './pages/PriceAnalyzer';
import PaymentMethods from './pages/PaymentMethods';

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const { currentUser, loading } = useAuth();

    if (loading) {
        return <div>Загрузка...</div>;
    }

    return currentUser ? children : <Navigate to="/cabinet/login" />;
};

const App: React.FC = () => {
    return (
        <AuthProvider>
            <Router>
                <GlobalStyle />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/payment-success" element={<PaymentSuccessPage />} />

                    <Route path="/cabinet/set_password/:token" element={<SetPassword />} />
                    <Route path="/cabinet/login" element={<Login />} />
                    <Route
                        path="/cabinet"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/cabinet/course/:courseId"
                        element={
                            <PrivateRoute>
                                <CourseDetail />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/cabinet/video/:video_id"
                        element={
                            <PrivateRoute>
                                <VideoPlayer />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/cabinet/price-analyzer"
                        element={
                            <PrivateRoute>
                                <PriceAnalyzer />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/cabinet/payment-methods"
                        element={
                            <PrivateRoute>
                                <PaymentMethods />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
