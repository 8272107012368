// src/pages/Dashboard.tsx
import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import styled from 'styled-components';
import CourseItem from '../components/Dashboard/CourseItem';
import Layout from '../components/Layout/Layout';

const Dashboard: React.FC = () => {
    const { currentUser, loading } = useAuth();

    if (loading) {
        return <LoadingContainer>Загрузка...</LoadingContainer>;
    }

    if (!currentUser) {
        return <ErrorContainer>Пользователь не найден</ErrorContainer>;
    }

    return (
        <Layout>
            <Title>Мои курсы</Title>
            {currentUser.courses && currentUser.courses.length > 0 ? (
                <CourseList>
                    {currentUser.courses.map((course) => (
                        <CourseItem key={course.course_id} course={course} />
                    ))}
                </CourseList>
            ) : (
                <NoCoursesMessage>У вас пока нет доступных курсов.</NoCoursesMessage>
            )}
        </Layout>
    );
};

const Title = styled.h1`
    color: #001B4F;
    font-size: 32px;
    margin-bottom: 20px;
`;

const CourseList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const LoadingContainer = styled.div`
    text-align: center;
    margin-top: 100px;
    font-size: 24px;
`;

const ErrorContainer = styled.div`
    text-align: center;
    margin-top: 100px;
    font-size: 24px;
    color: red;
`;

const NoCoursesMessage = styled.p`
    font-size: 18px;
    color: #333;
`;

export default Dashboard;
