// src/pages/PaymentMethods.tsx
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import Layout from '../components/Layout/Layout';
import styled from 'styled-components';

const PaymentMethods: React.FC = () => {
    const { currentUser } = useAuth();
    // Пример состояния привязанных карт (как было в CourseItem, но теперь здесь)
    const [linkedCards, setLinkedCards] = useState([
        {
            id: 'card_1',
            brand: 'Visa',
            last4: '4242',
            expMonth: 12,
            expYear: 2024,
        },
        {
            id: 'card_2',
            brand: 'MasterCard',
            last4: '5555',
            expMonth: 6,
            expYear: 2025,
        },
    ]);

    const handleDeleteCard = (cardId: string) => {
        setLinkedCards(prevCards => prevCards.filter(card => card.id !== cardId));
    };

    return (
        <Layout>
            <Title>Методы оплаты</Title>
            {currentUser && currentUser.id === 13 && (
                <CardsSection>
                    <SectionHeader>Привязанные карты</SectionHeader>
                    {linkedCards.length === 0 ? (
                        <NoCardsText>Нет привязанных карт.</NoCardsText>
                    ) : (
                        linkedCards.map(card => (
                            <CardItem key={card.id}>
                                <CardInfo>
                                    <CardBrand>{card.brand}</CardBrand>
                                    <CardNumber>•••• {card.last4}</CardNumber>
                                    <CardExpiry>Срок действия: {card.expMonth}/{card.expYear}</CardExpiry>
                                </CardInfo>
                                <DeleteCardButton onClick={() => handleDeleteCard(card.id)}>
                                    Удалить
                                </DeleteCardButton>
                            </CardItem>
                        ))
                    )}
                </CardsSection>
            )}
            {/* Здесь же можно добавить функционал повторных оплат, запланированных платежей и т.п. */}
        </Layout>
    );
};

const Title = styled.h1`
    color: #001B4F;
    font-size: 32px;
    margin-bottom: 20px;
`;

const CardsSection = styled.div`
    margin-top: 20px;
    background-color: #e3f2fd;
    padding: 20px;
    border-radius: 12px;
`;

const SectionHeader = styled.h4`
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 700;
    color: #001b4f;
`;

const NoCardsText = styled.p`
    font-size: 14px;
    color: #555;
`;

const CardItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 10px;
`;

const CardInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const CardBrand = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: #001b4f;
`;

const CardNumber = styled.span`
    font-size: 14px;
    color: #333;
`;

const CardExpiry = styled.span`
    font-size: 12px;
    color: #777;
`;

const DeleteCardButton = styled.button`
    background-color: #d32f2f;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 24px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #c62828;
    }

    &:focus {
        outline: none;
    }

    &:active {
        background-color: #b71c1c;
    }
`;

export default PaymentMethods;
