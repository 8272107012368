// src/components/Layout/TopBar.tsx
import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';

const TopBar: React.FC = () => {
    const { currentUser, logout } = useAuth();

    const handleLogout = () => {
        logout();
        window.location.href = '/login';
    };

    return (
        <TopBarContainer>
            <WelcomeText>
                {currentUser ? `Добро пожаловать, ${currentUser.first_name}` : 'Добро пожаловать'}
            </WelcomeText>
            <LogoutButton onClick={handleLogout}>Выйти</LogoutButton>
        </TopBarContainer>
    );
};

const TopBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const WelcomeText = styled.div`
    font-size: 18px;
    color: #001b4f;
`;

const LogoutButton = styled.button`
    background-color: #FF9900;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
`;

export default TopBar;
