import React from 'react';
import styled from 'styled-components';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLeft>
          <h3>Контакты</h3>
          <p>info@amazonfreedom.ru</p>
        </FooterLeft>
        <FooterLinks>
          <a href="./terms">Пользовательское соглашение / Terms</a>
        </FooterLinks>
        <FooterSocial>
          <p>Соцсети</p>
          <a href="https://t.me/juliaintheusachat">Telegram</a>
        </FooterSocial>
      </FooterContent>
      <FooterBottom>
        <FooterBottomLeft>
          <p>Кузьмич Юлия Юрьевна</p>
          <p>ИНН: 860237504280</p>
        </FooterBottomLeft>
        <FooterBottomRight>
          <p>Rare Supply LLC</p>
        </FooterBottomRight>
      </FooterBottom>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  justify-content: center;
  align-items: center;
  background: linear-gradient(160deg, #0b172e -0.66%, #2b4a87 176.61%);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1603px;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 700;
  @media (max-width: 991px) {
    align-items: center;
    text-align: center;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 18px;
  padding: 2px 0;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
  a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 700;
  }
`;

const FooterSocial = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  @media (max-width: 991px) {
    align-items: center;
  }
  p, a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 700;
  }
`;

const FooterBottom = styled.div`
  margin-top: 24px;
  width: 100%;
  gap: 20px;
  font-size: 18px;
  color: #909090;
  font-weight: 400;
  line-height: 140%;
  justify-content: space-between;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterBottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 400;
  @media (max-width: 991px) {
    align-items: center;
    text-align: center;
  }
`;

const FooterBottomRight = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 400;
  @media (max-width: 991px) {
    align-items: center;
    text-align: center;
  }
`;

export default Footer;
