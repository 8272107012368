import React, { useState } from 'react';
import styled from 'styled-components';

const questionsAndAnswers = [
  {
    question: "Подходит ли ваш курс для новичков, которые только начинают и не имеют никакого опыта?",
    answer: "Наш курс разработан специально для новичков, чтобы вы могли с легкостью освоить все необходимые знания и навыки с нуля. Мы шаг за шагом проведем вас через весь процесс, обеспечивая полную поддержку на каждом этапе вашего обучения."
  },
  {
    question: "Сколько длится курс?",
    answer: "Прохождение курса зависит от вашей скорости обучения, в среднем достаточно 1-4 недель для освоения материала."
  },
  {
    question: "Какой уровень владения английским языком необходим?",
    answer: "Достаточно базовых знаний английского языка. Весь материал преподносится на русском языке."
  },
  {
    question: "Какие нужны минимальные вложения?",
    answer: "Минимальные вложения зависят от выбранного курса и вашей стратегии работы."
  },
  {
    question: "Подходит ли курс для тех, кто не находится в США?",
    answer: "Да, вы можете открыть компанию в США удаленно и вести весь бизнес из своего дома."
  },
  {
    question: "Есть ли готовый пакет услуг по запуску бизнеса?",
    answer: "Да, у нас есть готовые пакеты услуг, которые помогут вам запустить бизнес."
  }
];

const FAQSection: React.FC = () => {
  const [openIndexes, setOpenIndexes] = useState<boolean[]>(Array(questionsAndAnswers.length).fill(false));

  const toggleFAQ = (index: number) => {
    setOpenIndexes(openIndexes.map((open, i) => (i === index ? !open : open)));
  };

  return (
    <OuterContainer id="faq">
      <FAQTitle>FAQ</FAQTitle>
      <FAQContainer>
        <FAQTable>
          {questionsAndAnswers.map((item, index) => (
            <FAQItem key={index}>
              <FAQRow onClick={() => toggleFAQ(index)}>
                <FAQQuestion>{item.question}</FAQQuestion>
                <ToggleIcon open={openIndexes[index]} />
              </FAQRow>
              {openIndexes[index] && <FAQAnswer>{item.answer}</FAQAnswer>}
              <Divider />
            </FAQItem>
          ))}
        </FAQTable>
      </FAQContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  padding: 0 60px;
  margin: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 64px;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    padding: 20px;
    gap: 40px;
  }
`;

const FAQTitle = styled.div`
  align-self: flex-start;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 110%;
  color: #FF9900;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const FAQTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 40px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 991px) {
    gap: 20px;
  }
`;

const FAQItem = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const FAQRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #0E0B2C;
  &:hover {
    color: #FF9900;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FAQQuestion = styled.div`
  font-size: 24px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FAQAnswer = styled.div`
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #0E0B2C;
  opacity: 0.8;
  margin-top: 10px;
`;

const ToggleIcon = styled.div<{ open: boolean }>`
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: ${({ open }) => (open ? 'none' : '6px solid #0E0B2C')};
  border-bottom: ${({ open }) => (open ? '6px solid #0E0B2C' : 'none')};
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease;
  @media (max-width: 991px) {
    align-self: flex-end;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #909090;
  margin-top: 20px;
`;

export default FAQSection;
