// src/components/Layout/Layout.tsx
import React from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import TopBar from './TopBar';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <LayoutContainer>
            <Sidebar />
            <ContentContainer>
                <TopBar />
                <MainContent>{children}</MainContent>
            </ContentContainer>
        </LayoutContainer>
    );
};

const LayoutContainer = styled.div`
    display: flex;
    height: 100vh;
    font-family: 'Jost', sans-serif;
`;

const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const MainContent = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 40px 20px;
    background: #f9f9f9;
`;

export default Layout;
