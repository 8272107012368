// src/pages/PriceAnalyzer.tsx

import React, { useState, useMemo, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import styled from 'styled-components';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface SupplierRow {
    upc: string;
    price: number;
}

interface AmazonRow {
    ASIN: string;
    Title: string;
    UPC: string;
    BuyBoxPrice: number;
    FBAFee: number;
    ReferralFee: number;
    NumberOfItems: number;
    WeightG: number;
    Drops90Days: number;
    Sellers90Days: number;
}

interface ResultRow {
    upc: string;
    asin: string;
    title: string;
    supplierPrice: number;
    buyBoxPrice: number;
    fbaFee: number;
    referralFee: number;
    numberOfItems: number;
    weightG: number;
    drops90Days: number;
    sellers90Days: number;
    profit: number;
    roi: number;
}

type SortColumn = keyof ResultRow | null;
type SortDirection = 'asc' | 'desc';

const PriceAnalyzer: React.FC = () => {
    const [supplierData, setSupplierData] = useState<SupplierRow[]>([]);
    const [amazonData, setAmazonData] = useState<AmazonRow[]>([]);
    const [results, setResults] = useState<ResultRow[]>([]);

    const [supplierFile, setSupplierFile] = useState<File | null>(null);
    const [amazonFile, setAmazonFile] = useState<File | null>(null);

    const [manualSupplierInput, setManualSupplierInput] = useState<string>("");

    const [showUPCs, setShowUPCs] = useState(false);

    const [sortColumn, setSortColumn] = useState<SortColumn>(null);
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

    const [supplierHeaders, setSupplierHeaders] = useState<string[]>([]);
    const [supplierUPCColumn, setSupplierUPCColumn] = useState<string>("");
    const [supplierPriceColumn, setSupplierPriceColumn] = useState<string>("");
    const [columnsChosen, setColumnsChosen] = useState(false);

    const [supplierHeaderRow, setSupplierHeaderRow] = useState<number>(1);
    const [amazonHeaderRow, setAmazonHeaderRow] = useState<number>(1);

    const [loading, setLoading] = useState(false);

    const [supplierPricePerUnit, setSupplierPricePerUnit] = useState<boolean>(false);

    useEffect(() => {
        if (supplierFile) {
            detectSupplierHeaders(supplierFile, supplierHeaderRow).then(headers => {
                setSupplierHeaders(headers);
                setColumnsChosen(false);
            }).catch(() => {
                setSupplierHeaders([]);
            });
        } else {
            setSupplierHeaders([]);
        }
    }, [supplierFile, supplierHeaderRow]);

    const handleSupplierFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSupplierFile(e.target.files[0]);
            setColumnsChosen(false);
        }
    };

    const handleAmazonFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setAmazonFile(e.target.files[0]);
        }
    };

    function getFileExtension(fileName: string): string {
        const parts = fileName.toLowerCase().split('.');
        return parts[parts.length - 1];
    }

    const parsePrice = (val: any): number => {
        if (val == null) return NaN;
        const strVal = val.toString();
        return parseFloat(strVal.replace(/[^0-9.]/g, ''));
    };

    async function detectSupplierHeaders(file: File, headerRow: number): Promise<string[]> {
        const ext = getFileExtension(file.name);
        if (ext === 'csv') {
            const content = await readFileAsText(file);
            const lines = content.split('\n').map(l => l.trim()).filter(l => l.length > 0);
            if (lines.length < headerRow) {
                return [];
            }
            const headerLine = lines[headerRow - 1];
            const parsed = Papa.parse(headerLine, { header: false });
            if (parsed.data.length > 0) {
                return (parsed.data[0] as string[]).map(h => h.trim());
            }
            return [];
        } else if (ext === 'xlsx' || ext === 'xls') {
            const arrayBuffer = await file.arrayBuffer();
            const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as any[][];
            if (json.length < headerRow) return [];
            const headers = json[headerRow - 1].map((h: any) => h.toString().trim());
            return headers;
        } else {
            return [];
        }
    }

    async function readFileAsText(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsText(file);
        });
    }

    async function parseSupplierFileFull(): Promise<SupplierRow[]> {
        if (!supplierFile && manualSupplierInput.trim().length > 0) {
            const lines = manualSupplierInput.trim().split("\n");
            const parsed = lines.map(line => {
                const parts = Papa.parse(line).data[0] as string[];
                if (parts.length >= 2) {
                    const upc = parts[0].trim();
                    const priceVal = parsePrice(parts[1].trim());
                    if (upc && !isNaN(priceVal)) {
                        return { upc, price: priceVal };
                    }
                }
                return null;
            }).filter(Boolean) as SupplierRow[];
            return parsed;
        }

        if (!supplierFile) {
            return [];
        }

        const ext = getFileExtension(supplierFile.name);
        if (ext === 'csv') {
            const content = await readFileAsText(supplierFile);
            const lines = content.split('\n').map(l => l.trim());
            if (lines.length < supplierHeaderRow) return [];
            const headerLine = lines[supplierHeaderRow - 1];
            const headerParsed = Papa.parse(headerLine, { header: false });
            const headers = (headerParsed.data[0] as string[]).map(h => h.trim());

            const dataLines = lines.slice(supplierHeaderRow);
            const rows: SupplierRow[] = [];
            for (const line of dataLines) {
                if (!line.trim()) continue;
                const parsed = Papa.parse(line, { header: false }).data[0] as string[];
                const upcVal = parsed[headers.indexOf(supplierUPCColumn)];
                const priceValRaw = parsed[headers.indexOf(supplierPriceColumn)];
                if (upcVal && priceValRaw !== undefined) {
                    const priceVal = parsePrice(priceValRaw.toString());
                    if (!isNaN(priceVal)) {
                        rows.push({ upc: upcVal.toString().trim(), price: priceVal });
                    }
                }
            }
            return rows;
        } else if (ext === 'xlsx' || ext === 'xls') {
            const arrayBuffer = await supplierFile.arrayBuffer();
            const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as any[][];

            if (json.length < supplierHeaderRow) return [];

            const headers = json[supplierHeaderRow - 1].map((h: any) => h.toString());
            const upcIndex = headers.indexOf(supplierUPCColumn);
            const priceIndex = headers.indexOf(supplierPriceColumn);

            if (upcIndex === -1 || priceIndex === -1) return [];

            const parsed = json.slice(supplierHeaderRow).map(row => {
                if (!row || row.length === 0) return null;
                const upcVal = row[upcIndex];
                const priceVal = parsePrice((row[priceIndex] || "").toString());
                if (upcVal && !isNaN(priceVal)) {
                    return {
                        upc: upcVal.toString().trim(),
                        price: priceVal
                    };
                }
                return null;
            }).filter(Boolean) as SupplierRow[];
            return parsed;
        } else {
            return [];
        }
    }

    function extractNumberOfItemsFromTitle(title: string, currentNumberOfItems: number): number {
        if (!title) return currentNumberOfItems;

        let regex = /Pack of (\d+)/i;
        let match = title.match(regex);
        if (match && match[1]) {
            const packNumber = parseInt(match[1], 10);
            if (!isNaN(packNumber) && packNumber > 1) return packNumber;
        }

        regex = /(\d+)\s*pcs?/i;
        match = title.match(regex);
        if (match && match[1]) {
            const pcNumber = parseInt(match[1], 10);
            if (!isNaN(pcNumber) && pcNumber > 1) return pcNumber;
        }

        regex = /(\d+)\s*count/i;
        match = title.match(regex);
        if (match && match[1]) {
            const countNumber = parseInt(match[1], 10);
            if (!isNaN(countNumber) && countNumber > 1) return countNumber;
        }

        return currentNumberOfItems;
    }

    async function parseAmazonFileFull(): Promise<AmazonRow[]> {
        if (!amazonFile) {
            return [];
        }

        const ext = getFileExtension(amazonFile.name);

        const parseAmazonRow = (d: any) => {
            const upc = (d["Product Codes: UPC"] || "").toString().trim();
            if (!upc) return null;

            const buyBoxPrice = parsePrice(d["Buy Box 🚚: Current"]);
            const fbaFee = parsePrice(d["FBA Pick&Pack Fee"]);
            const referralFee = parsePrice(d["Referral Fee based on current Buy Box price"]);
            let numberOfItems = parseInt(d["Number of Items"] || "1", 10);
            const weightG = parseInt(d["Item: Weight (g)"] || "0", 10);
            const drops90Days = parseInt(d["Sales Rank: Drops last 90 days"] || "0", 10);
            const sellers90Days = parseInt(d["Buy Box: Winner Count 90 days"] || "0", 10);
            const asin = d["ASIN"] || "";
            const title = d["Title"] || "";

            if (!buyBoxPrice || isNaN(buyBoxPrice)) return null;
            if (!asin) return null;

            numberOfItems = extractNumberOfItemsFromTitle(title, numberOfItems);

            return {
                ASIN: asin,
                Title: title,
                UPC: upc,
                BuyBoxPrice: isNaN(buyBoxPrice) ? 0 : buyBoxPrice,
                FBAFee: isNaN(fbaFee) ? 0 : fbaFee,
                ReferralFee: isNaN(referralFee) ? 0 : referralFee,
                NumberOfItems: isNaN(numberOfItems) ? 1 : numberOfItems,
                WeightG: isNaN(weightG) ? 0 : weightG,
                Drops90Days: isNaN(drops90Days) ? 0 : drops90Days,
                Sellers90Days: isNaN(sellers90Days) ? 0 : sellers90Days
            } as AmazonRow;
        };

        if (ext === 'csv') {
            const content = await readFileAsText(amazonFile);
            const lines = content.split('\n').map(l => l.trim());

            if (lines.length < amazonHeaderRow) return [];

            const headerLine = lines[amazonHeaderRow - 1];
            const headerParsed = Papa.parse(headerLine, { header: false });
            const headers = (headerParsed.data[0] as string[]).map(h => h.trim());

            const dataLines = lines.slice(amazonHeaderRow);
            const rows: AmazonRow[] = [];
            for (const line of dataLines) {
                if (!line.trim()) continue;
                const parsed = Papa.parse(line, { header: false }).data[0] as string[];
                const obj: any = {};
                headers.forEach((h, i) => {
                    obj[h] = parsed[i];
                });
                const arow = parseAmazonRow(obj);
                if (arow) rows.push(arow);
            }
            return rows;
        } else if (ext === 'xlsx' || ext === 'xls') {
            const arrayBuffer = await amazonFile.arrayBuffer();
            const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as any[][];

            if (jsonData.length < amazonHeaderRow) return [];
            const headers = jsonData[amazonHeaderRow - 1];
            const dataRows = jsonData.slice(amazonHeaderRow);

            const rows = dataRows.map(row => {
                const obj: any = {};
                headers.forEach((h: any, i: number) => {
                    obj[h] = row[i];
                });
                return parseAmazonRow(obj);
            }).filter(Boolean) as AmazonRow[];
            return rows;
        } else {
            return [];
        }
    }

    const handleConfirmColumns = () => {
        if (!supplierUPCColumn || !supplierPriceColumn) {
            alert("Пожалуйста, выберите колонки для UPC и Price");
            return;
        }
        setColumnsChosen(true);
    };

    const handleAnalyze = async () => {
        if (supplierFile && !columnsChosen) {
            alert("Пожалуйста, выберите колонки для UPC и Price и подтвердите.");
            return;
        }

        setLoading(true);
        try {
            const sData = await parseSupplierFileFull();
            const aData = await parseAmazonFileFull();

            setSupplierData(sData);
            setAmazonData(aData);

            const result: ResultRow[] = [];
            for (const sRow of sData) {
                const match = aData.find(a => a.UPC === sRow.upc);
                if (match) {
                    const supplierCost = supplierPricePerUnit && match.NumberOfItems > 1
                        ? sRow.price * match.NumberOfItems
                        : sRow.price;

                    const profit = (match.BuyBoxPrice - supplierCost - match.FBAFee - match.ReferralFee);
                    const roi = (profit / supplierCost) * 100;

                    result.push({
                        upc: sRow.upc,
                        asin: match.ASIN,
                        title: match.Title,
                        supplierPrice: supplierCost,
                        buyBoxPrice: match.BuyBoxPrice,
                        fbaFee: match.FBAFee,
                        referralFee: match.ReferralFee,
                        numberOfItems: match.NumberOfItems,
                        weightG: match.WeightG,
                        drops90Days: match.Drops90Days,
                        sellers90Days: match.Sellers90Days,
                        profit,
                        roi
                    });
                }
            }

            setResults(result);
        } catch (error) {
            console.error(error);
            alert("Произошла ошибка при анализе. Проверьте формат данных.");
        } finally {
            setLoading(false);
        }
    };

    const exportToExcel = () => {
        const wsData = [
            [
                "UPC", "ASIN", "Title", "Supplier Price", "Buy Box Price",
                "FBA Fee", "Referral Fee", "Number of Items", "Weight (g)",
                "Drops (90 days)", "Sellers (90 days)", "Profit", "ROI (%)"
            ],
            ...results.map(r => [
                r.upc, r.asin, r.title, r.supplierPrice, r.buyBoxPrice,
                r.fbaFee, r.referralFee, r.numberOfItems, r.weightG,
                r.drops90Days, r.sellers90Days, r.profit, r.roi
            ])
        ];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, "Results");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), "analysis_results.xlsx");
    };

    const getUPCsOnly = () => {
        const upcs = supplierData.map(d => d.upc).join("\n");
        return upcs;
    };

    const handleShowUPCs = async () => {
        if (supplierFile && !columnsChosen) {
            alert("Пожалуйста, выберите колонки для UPC и Price и подтвердите.");
            return;
        }

        setLoading(true);
        try {
            const sData = await parseSupplierFileFull();
            setSupplierData(sData);
            setShowUPCs(true);
        } catch (error) {
            console.error(error);
            alert("Ошибка при получении номеров.");
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (column: SortColumn) => {
        if (!column) return;
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const sortedResults = useMemo(() => {
        if (!sortColumn) return results;

        return [...results].sort((a, b) => {
            const valA = a[sortColumn];
            const valB = b[sortColumn];

            if (typeof valA === 'string' && typeof valB === 'string') {
                return sortDirection === 'asc'
                    ? valA.localeCompare(valB)
                    : valB.localeCompare(valA);
            } else if (typeof valA === 'number' && typeof valB === 'number') {
                return sortDirection === 'asc'
                    ? valA - valB
                    : valB - valA;
            } else {
                return 0;
            }
        });
    }, [results, sortColumn, sortDirection]);

    const renderSortIndicator = (column: SortColumn) => {
        if (sortColumn === column) {
            return sortDirection === 'asc' ? ' ▲' : ' ▼';
        }
        return null;
    };

    return (
        <Layout>
            <Title>Анализатор прайс-листов</Title>

            <InstructionContainer>
                <InstructionTitle>Инструкция</InstructionTitle>
                <InstructionList>
                    <li>Сначала загрузите или вручную введите список товаров (поставщика).
                        <ul>
                            <li>Поддерживаются файлы CSV и Excel (XLS, XLSX).</li>
                            <li>Если используете файл: вы можете указать с какой строки начинаются заголовки (по умолчанию 1).</li>
                            <li>После загрузки файла выберите колонки для UPC и Price.</li>
                            <li>Если ввод вручную: каждая строка "код,цена" (знаки валюты будут удалены).</li>
                        </ul>
                    </li>
                    <li>Если цена поставщика за штуку, а в названии товара есть "Pack of X" или "2 Pc", "2 pcs", "2 count" и т.п., количество будет скорректировано автоматически.</li>
                    <li>Нажмите "Получить только номера без прайса", чтобы получить список кодов для Keepa.</li>
                    <li>Затем загрузите CSV или Excel из Keepa. Аналогично можно указать строку заголовков.</li>
                    <li>Нажмите "Анализировать" для вычисления метрик.</li>
                    <li>Нажмите "Экспорт в Excel" для сохранения результатов.</li>
                    <li>Нажмите на заголовок столбца для сортировки.</li>
                    <li>Кликайте по ASIN, чтобы открыть товар на Amazon.</li>
                </InstructionList>
            </InstructionContainer>

            <SectionTitle>Шаг 1: Данные поставщика</SectionTitle>
            <Description>Выберите файл (CSV/XLS/XLSX) или введите данные вручную. Если заголовки не на первой строке, укажите номер:</Description>

            <Row>
                <Label>Строка заголовков (поставщик):</Label>
                <HeaderInput
                    type="number"
                    min={1}
                    value={supplierHeaderRow}
                    onChange={(e) => setSupplierHeaderRow(parseInt(e.target.value, 10))}
                />
            </Row>

            <UploadContainer>
                <FileInputContainer>
                    <Label>Файл поставщика (с заголовками):</Label>
                    <input type="file" accept=".csv,.xls,.xlsx" onChange={handleSupplierFileChange} />
                </FileInputContainer>
            </UploadContainer>

            {supplierFile && supplierHeaders.length > 0 && !columnsChosen && (
                <ColumnsContainer>
                    <h4>Выберите колонки:</h4>
                    <Label>UPC колонка:</Label>
                    <Select onChange={(e) => setSupplierUPCColumn(e.target.value)} value={supplierUPCColumn}>
                        <option value="">Выбрать...</option>
                        {supplierHeaders.map((h, i) => (
                            <option key={i} value={h}>{h}</option>
                        ))}
                    </Select>
                    <Label>Price колонка:</Label>
                    <Select onChange={(e) => setSupplierPriceColumn(e.target.value)} value={supplierPriceColumn}>
                        <option value="">Выбрать...</option>
                        {supplierHeaders.map((h, i) => (
                            <option key={i} value={h}>{h}</option>
                        ))}
                    </Select>
                    <ConfirmButton onClick={handleConfirmColumns}>Применить</ConfirmButton>
                </ColumnsContainer>
            )}

            <Row>
                <Label>
                    <input
                        type="checkbox"
                        checked={supplierPricePerUnit}
                        onChange={(e) => setSupplierPricePerUnit(e.target.checked)}
                    />
                    Цена поставщика указана за 1 штуку
                </Label>
            </Row>

            <Description>Или введите данные вручную:</Description>
            <ManualInputArea
                placeholder="Пример:&#10;123456789012,10.99&#10;234567890123,5.50"
                value={manualSupplierInput}
                onChange={(e) => setManualSupplierInput(e.target.value)}
            />

            <GetUPCsButton onClick={handleShowUPCs}>Получить только номера без прайса</GetUPCsButton>
            {showUPCs && supplierData.length > 0 && (
                <UPCsContainer>
                    <Label>Список номеров товаров для Keepa (скопируйте):</Label>
                    <UPCsTextArea readOnly value={getUPCsOnly()} />
                </UPCsContainer>
            )}

            <SectionTitle>Шаг 2: Данные с Keepa</SectionTitle>
            <Description>Загрузите CSV/XLSX из Keepa. Если заголовки не с первой строки, укажите номер:</Description>

            <Row>
                <Label>Строка заголовков (Amazon):</Label>
                <HeaderInput
                    type="number"
                    min={1}
                    value={amazonHeaderRow}
                    onChange={(e) => setAmazonHeaderRow(parseInt(e.target.value, 10))}
                />
            </Row>

            <UploadContainer>
                <FileInputContainer>
                    <Label>Файл Amazon (CSV/XLS/XLSX):</Label>
                    <input type="file" accept=".csv,.xls,.xlsx" onChange={handleAmazonFileChange} />
                </FileInputContainer>
            </UploadContainer>

            <AnalyzeButton
                disabled={
                    (supplierFile && !columnsChosen) ||
                    (supplierData.length === 0 && (!supplierFile && manualSupplierInput.trim() === "")) ||
                    !amazonFile
                }
                onClick={handleAnalyze}
            >Анализировать</AnalyzeButton>

            {loading && <LoadingText>Загрузка...</LoadingText>}

            {!loading && sortedResults.length > 0 && (
                <>
                    <ExportButton onClick={exportToExcel}>Экспорт в Excel</ExportButton>
                    <ResultsContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <Th onClick={() => handleSort('upc')}>UPC{renderSortIndicator('upc')}</Th>
                                    <Th onClick={() => handleSort('asin')}>ASIN{renderSortIndicator('asin')}</Th>
                                    <Th onClick={() => handleSort('title')}>Title{renderSortIndicator('title')}</Th>
                                    <Th onClick={() => handleSort('supplierPrice')}>Supplier Price{renderSortIndicator('supplierPrice')}</Th>
                                    <Th onClick={() => handleSort('buyBoxPrice')}>Buy Box Price{renderSortIndicator('buyBoxPrice')}</Th>
                                    <Th onClick={() => handleSort('fbaFee')}>FBA Fee{renderSortIndicator('fbaFee')}</Th>
                                    <Th onClick={() => handleSort('referralFee')}>Referral Fee{renderSortIndicator('referralFee')}</Th>
                                    <Th onClick={() => handleSort('numberOfItems')}># Items{renderSortIndicator('numberOfItems')}</Th>
                                    <Th onClick={() => handleSort('weightG')}>Weight (g){renderSortIndicator('weightG')}</Th>
                                    <Th onClick={() => handleSort('drops90Days')}>Drops (90d){renderSortIndicator('drops90Days')}</Th>
                                    <Th onClick={() => handleSort('sellers90Days')}>Sellers (90d){renderSortIndicator('sellers90Days')}</Th>
                                    <Th onClick={() => handleSort('profit')}>Profit{renderSortIndicator('profit')}</Th>
                                    <Th onClick={() => handleSort('roi')}>ROI (%){renderSortIndicator('roi')}</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedResults.map((r, idx) => (
                                    <tr key={idx}>
                                        <Td>{r.upc}</Td>
                                        <Td><a href={`https://www.amazon.com/dp/${r.asin}`} target="_blank" rel="noreferrer">{r.asin}</a></Td>
                                        <Td>{r.title}</Td>
                                        <Td>{r.supplierPrice.toFixed(2)}</Td>
                                        <Td>{r.buyBoxPrice.toFixed(2)}</Td>
                                        <Td>{r.fbaFee.toFixed(2)}</Td>
                                        <Td>{r.referralFee.toFixed(2)}</Td>
                                        <Td>{r.numberOfItems}</Td>
                                        <Td>{r.weightG}</Td>
                                        <Td>{r.drops90Days}</Td>
                                        <Td>{r.sellers90Days}</Td>
                                        <Td style={{ color: r.profit > 0 ? 'green' : 'red' }}>{r.profit.toFixed(2)}</Td>
                                        <Td style={{ color: r.roi > 0 ? 'green' : 'red' }}>{r.roi.toFixed(2)}%</Td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </ResultsContainer>
                </>
            )}
        </Layout>
    );
};

const Title = styled.h1`
    color: #001B4F;
    font-size: 32px;
    margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
    color: #001B4F;
    font-size: 24px;
    margin: 40px 0 20px;
`;

const Description = styled.p`
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
`;

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
`;

const FileInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Label = styled.label`
    font-size: 14px;
    color: #001B4F;
    font-weight: 600;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
`;

const HeaderInput = styled.input`
    width: 60px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
`;

const AnalyzeButton = styled.button`
    width: 200px;
    padding: 10px 20px;
    background-color: #ff9900;
    color: #ffffff;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 20px;

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }

    &:hover:enabled {
        background-color: #ff8c00;
    }
`;

const ExportButton = styled.button`
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #ffffff;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;

    &:hover {
        background-color: #45a049;
    }
`;

const ResultsContainer = styled.div`
    margin-top: 40px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
`;

const Th = styled.th`
    background-color: #E3F2FD;
    color: #0D47A1;
    text-align: left;
    padding: 10px;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 2px solid #ccc;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: #dcecff;
    }
`;

const Td = styled.td`
    padding: 10px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #eee;

    a {
        color: #0D47A1;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const ManualInputArea = styled.textarea`
    width: 100%;
    height: 100px;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-family: sans-serif;
    resize: vertical;
    margin-bottom: 20px;
`;

const GetUPCsButton = styled.button`
    padding: 10px 20px;
    background-color: #2196f3;
    color: #ffffff;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;

    &:hover {
        background-color: #1976d2;
    }
`;

const UPCsContainer = styled.div`
    margin-bottom: 20px;
`;

const UPCsTextArea = styled.textarea`
    width: 100%;
    height: 100px;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-family: sans-serif;
    resize: vertical;
`;

const InstructionContainer = styled.div`
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const InstructionTitle = styled.h3`
    color: #001B4F;
    font-size: 20px;
    margin-bottom: 10px;
`;

const InstructionList = styled.ul`
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    li {
        margin-bottom: 10px;
    }
    code {
        background: #eee;
        padding: 2px 4px;
        border-radius: 4px;
        font-size: 90%;
    }
`;

const ColumnsContainer = styled.div`
    background: #e3f2fd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;

    h4 {
        margin-top: 0;
    }
`;

const Select = styled.select`
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    display: block;
`;

const ConfirmButton = styled.button`
    padding: 10px 20px;
    background-color: #ff9900;
    color: #ffffff;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
        background-color: #ff8c00;
    }
`;

const LoadingText = styled.div`
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    font-weight: 600;
`;

export default PriceAnalyzer;
