import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51PTap92KbS5VetKef3ZFhoAhKU9A2HFIhTFKzJE3W97nzkL6pdPmZS72OGQYxJtJyC9rEBZeXF8btolc8La7w6Mm00ltNb1DeT');

interface Course {
  id: number;
  name: string;
  price_usd: number;
  price_rub: number;
  discounted_price_usd: number;
  discounted_price_rub: number;
  discount_end_date: string;
  discount_percentage: number;
  active: boolean;
  currency?: 'usd' | 'rub';
}

const courseDetails: { [key: string]: string[] } = {
  'Course 1': ['Этот курс находится в разработке.', 'Курс пока недоступен'],
  'Course 2': [
    'Открытие компании в США (регистрация LLC, получение налоговых номеров, подготовка к работе на Amazon)',
    'Основы работы с Sellercentral (настройка аккаунта, навигация по интерфейсу, управление продажами)',
    'Поиск поставщиков (выбор надежных поставщиков, анализ прайс-листов, оптимизация закупок)',
    'Методы поиска прибыльных товаров + 🎁 подарок товар для старта (стратегии нахождения товаров, анализ конкурентов, использование инструментов для оценки спроса)',
    'Основы работы с 3PL (взаимодействие с логистическими центрами, оптимизация цепочек поставок)',
    'Юнит экономика (расчет себестоимости, ROI, прибыль на единицу, финансовое планирование)',
    'Отправка товара на склад Амазона (создание шипментов, выбор FBA или FBM, работа с преп-центрами)'
  ],
  'Course 3': ['Этот курс находится в разработке.', 'Курс пока недоступен'],
};

const CourseSection: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [selectedContent, setSelectedContent] = useState<string | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [sourceTag, setSourceTag] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tag = urlParams.get('source_tag');
    if (tag) {
      setSourceTag(tag);
      document.cookie = `source_tag=${tag}; path=/`;
    }
    axios.get<Course[]>('/api/courses').then(response => {
      setCourses(response.data);
    });
  }, []);

  const toggleBodyScroll = (disable: boolean) => {
    if (disable) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  const handleOpenModal = (content: string) => {
    setSelectedContent(content);
    toggleBodyScroll(true);
  };

  const handleCloseModal = () => {
    setSelectedContent(null);
    setSelectedCourse(null);
    setModalOpen(false);
    toggleBodyScroll(false);
  };

  const handleBuyCourse = (course: Course, currency: 'usd' | 'rub') => {
    setSelectedCourse({ ...course, currency });
    setModalOpen(true);
  };

  const handleNotifyMe = (course: Course) => {
    setSelectedCourse(course);
    setModalOpen(true);
  };

  const handleSubmitEmail = () => {
    if (selectedCourse) {
      axios.post('/api/notify', { courseId: selectedCourse.id, email })
        .then(() => {
          alert('You will be notified when the course becomes available.');
          handleCloseModal();
        })
        .catch(err => console.error(err));
    }
  };

  return (
    <Section id="courses">
      <SectionTitle>НАШИ КУРСЫ</SectionTitle>
      <Subtitle>Купите сейчас и получите лучшие условия!</Subtitle>
      <CoursesContainer>
        {courses.sort((a, b) => a.id - b.id).map(course => {
          return (
            <CourseCard key={course.id} active={course.active} featured={course.id === 2}>
              <CourseHeader>
                <Column>
                  <CourseTitle>{course.name}</CourseTitle>
                  <CourseSubtitle>
                    {course.id === 1 && 'Подходит тем, кто хочет освоить азы Амазона и работать на селлеров'}
                    {course.id === 2 && 'Подходит тем, кто хочет открыть свой бизнес по системе онлайн арбитраж'}
                    {course.id === 3 && 'Подходит тем, кто хочет создать свой бренд с нуля и запустить его на Амазон'}
                  </CourseSubtitle>
                </Column>
                <Column2>
                  {course.discount_percentage > 0 && <Discount>-{course.discount_percentage}%</Discount>}
                </Column2>
              </CourseHeader>
              {course.active && <CourseContentTitle>В курсе:</CourseContentTitle>}
              <CourseContent>
                {course.active ? (
                  <CourseItemsList>
                    {courseDetails[`Course ${course.id}`].map((item, index) => (
                      <CourseItem key={index}>
                        {item}
                      </CourseItem>
                    ))}
                  </CourseItemsList>
                ) : (
                  <PlaceholderContent>{courseDetails[`Course ${course.id}`][1]}</PlaceholderContent>
                )}
              </CourseContent>
              <IncomeContainer>
                <IncomeTitle>Ожидаемый доход: </IncomeTitle>
                <Income>{course.id === 1 ? "800–2000$" : "1000$ - "}
                  <InfinitySymbol>∞</InfinitySymbol>
                </Income>
              </IncomeContainer>
              {course.active && course.discount_percentage > 0 && course.discount_end_date && (
                <DiscountNotice>
                  Успей купить со скидкой до {new Date(course.discount_end_date).toLocaleDateString()}
                </DiscountNotice>
              )}
              <PriceContainer>
                {course.active && (
                  <>
                    <Price>
                      ЦЕНА: {course.discounted_price_usd}$
                      {course.discount_percentage > 0 && <OldPrice>{course.price_usd}$</OldPrice>}
                    </Price>
                    <Price>
                      ЦЕНА: {course.discounted_price_rub}₽
                      {course.discount_percentage > 0 && <OldPrice>{course.price_rub}₽</OldPrice>}
                    </Price>
                  </>
                )}
                <ButtonContainer>
                  {course.active ? (
                    <>
                      <BuyButton onClick={() => handleBuyCourse(course, 'usd')}>Купить за $</BuyButton>
                      <BuyButton onClick={() => handleBuyCourse(course, 'rub')}>Купить за ₽</BuyButton>
                    </>
                  ) : (
                    <NotifyButton onClick={() => handleNotifyMe(course)}>Уведомить меня</NotifyButton>
                  )}
                </ButtonContainer>
                {!course.active && <NotifyText>Подпишитесь, чтобы узнать, когда курс станет доступным.</NotifyText>}
              </PriceContainer>
            </CourseCard>
          );
        })}
      </CoursesContainer>

      {selectedContent && (
        <ModalOverlay onClick={handleCloseModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalClose onClick={handleCloseModal}>&times;</ModalClose>
            <ModalTitle>{selectedContent}</ModalTitle>
            <ModalDescription>{courseDetails[selectedContent]}</ModalDescription>
          </ModalContent>
        </ModalOverlay>
      )}

      {modalOpen && selectedCourse && (
        <Elements stripe={stripePromise}>
          {selectedCourse.active ? (
            <PurchaseModal course={selectedCourse} onClose={handleCloseModal} sourceTag={sourceTag} />
          ) : (
            <NotifyModal course={selectedCourse} onClose={handleCloseModal} email={email} setEmail={setEmail} handleSubmitEmail={handleSubmitEmail} />
          )}
        </Elements>
      )}
    </Section>
  );
};

interface PurchaseModalProps {
  course: Course;
  onClose: () => void;
  sourceTag: string | null;
}

const PurchaseModal: React.FC<PurchaseModalProps> = ({ course, onClose, sourceTag }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [installmentPlan, setInstallmentPlan] = useState('');

  const handlePayment = async (paymentMethodId: string) => {
    if (!stripe) {
      setError('Stripe is not initialized');
      setLoading(false);
      return;
    }

    try {
      const { data } = await axios.post('/api/purchase', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        course_id: course.id,
        currency: course.currency,
        installment_plan: installmentPlan,
        paymentMethodId,
        source_tag: sourceTag,
      });

      const { client_secret, payment_id } = data;

      if (installmentPlan) {
        const { error: setupError, setupIntent } = await stripe.confirmCardSetup(client_secret);

        if (setupError) {
          console.error('Stripe setup error:', setupError);
          setError(setupError.message || 'An error occurred during setup confirmation');
        } else if (setupIntent && setupIntent.status === 'succeeded') {
          window.location.href = '/payment-success';
          onClose();
        } else {
          console.error('Unexpected setup intent status:', setupIntent);
          setError('An unexpected error occurred during setup confirmation');
        }
      } else {
        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(client_secret);

        if (confirmError) {
          console.error('Stripe confirmation error:', confirmError);
          setError(confirmError.message || 'An error occurred during payment confirmation');
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
          window.location.href = '/payment-success';
          onClose();
        } else {
          console.error('Unexpected payment intent status:', paymentIntent);
          setError('An unexpected error occurred during payment confirmation');
        }
      }
    } catch (error) {
      console.error('Payment handling error:', error);
      setError('An error occurred during the payment process');
    }
    setLoading(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (course.currency === 'usd') {
      if (!stripe || !elements) return;

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        setError('Card element not found');
        setLoading(false);
        return;
      }

      const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (paymentError) {
        setError(paymentError.message || 'An error occurred');
        setLoading(false);
        return;
      }

      if (paymentMethod) {
        await handlePayment(paymentMethod.id);
      }
    } else if (course.currency === 'rub') {
      try {
        const { data } = await axios.post('/api/yookassa/create-payment', {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          user_id: 1,
          course_id: course.id,
          installment_plan: installmentPlan,
          source_tag: sourceTag,
        });

        window.location.href = data.confirmation.confirmation_url;
      } catch (error) {
        setError('An error occurred');
        setLoading(false);
      }
    }
  };

  const installmentDetails = (plan: string) => {
    const totalInstallments = parseInt(plan);
    const installmentAmount =
      course.currency === 'usd'
        ? (course.discounted_price_usd / totalInstallments).toFixed(2)
        : (course.discounted_price_rub / totalInstallments).toFixed(2);
    const currencySymbol = course.currency === 'usd' ? '$' : '₽';
    return ` (${totalInstallments} payments of ${installmentAmount} ${currencySymbol} every week)`;
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalClose onClick={onClose}>&times;</ModalClose>

        <ModalTitle>Спасибо, что выбрали курс {course.name}!</ModalTitle>

        <MotivationalText>
          Начните свой путь к успешной торговле на Amazon уже сегодня! Не упустите возможность купить курс по выгодной цене.
        </MotivationalText>
        <form onSubmit={handleSubmit}>
          <InputField>
            <label>Имя:</label>
            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
          </InputField>
          <InputField>
            <label>Фамилия:</label>
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
          </InputField>
          <InputField>
            <label>Email:</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </InputField>
          <InputField>
            <label>Телефон:</label>
            <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} required />
          </InputField>
          <InputField>
            <label>План рассрочки:</label>
            <select value={installmentPlan} onChange={(e) => setInstallmentPlan(e.target.value)}>
              <option value="">Полная оплата - {course.currency === 'usd' ? `$${course.discounted_price_usd}` : `${course.discounted_price_rub}₽`}</option>
              <option value="2">2 платежа{installmentDetails("2")}</option>
              <option value="4">4 платежа{installmentDetails("4")}</option>
              <option value="8">8 платежей{installmentDetails("8")}</option>
            </select>
          </InputField>
          {course.currency === 'usd' && (
            <CardElementWrapper>
              <CardElement />
            </CardElementWrapper>
          )}
          {installmentPlan && (
            <InstallmentNotice>Обратите внимание: выбрав план рассрочки, ваша карта будет сохранена для последующих платежей. Курс будет доступен сразу после оплаты.</InstallmentNotice>
          )}

          {course.currency === 'usd' && (
            <PaymentMethodNotice>Доступна оплата картами Visa, MasterCard, Discover, Amex.</PaymentMethodNotice>
          )}
          {course.currency === 'rub' && (
            <PaymentMethodNotice>Оплата доступна только российскими картами.</PaymentMethodNotice>
          )}

          {error && <ErrorText>{error}</ErrorText>}
          <PurchaseButton type="submit" disabled={loading}>
            {loading ? 'Обработка...' : 'Оплатить'}
          </PurchaseButton>
        </form>
        <TermsNotice>
          Совершая оплату, вы соглашаетесь с <a href="https://amazonfreedom.ru/terms" target="_blank" rel="noopener noreferrer">условиями курса</a>.
        </TermsNotice>
      </ModalContent>
    </ModalOverlay>
  );
};

interface NotifyModalProps {
  course: Course;
  onClose: () => void;
  email: string;
  setEmail: (email: string) => void;
  handleSubmitEmail: () => void;
}

const NotifyModal: React.FC<NotifyModalProps> = ({ course, onClose, email, setEmail, handleSubmitEmail }) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalClose onClick={onClose}>&times;</ModalClose>
        <ModalTitle>Спасибо за интерес к курсу {course.name}!</ModalTitle>
        <ModalDescription>
          Мы работаем над тем, чтобы сделать этот курс ещё лучше. Оставьте свой email ниже, и мы уведомим вас, как только он станет доступен.
        </ModalDescription>
        <MotivationalText>
          Мы с нетерпением ждем возможности помочь вам достичь ваших целей с этим курсом. Оставайтесь с нами!
        </MotivationalText>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmitEmail(); }}>
          <InputField>
            <label>Ваш email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите ваш email"
              required
            />
          </InputField>
          <PurchaseButton type="submit">Уведомить меня</PurchaseButton>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

// Styled-components

const Section = styled.div`
  margin-top: 64px;
  max-width: 100%;
  padding: 0 60px;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const SectionTitle = styled.h2`
  color: #f90;
  font: 700 50px/100% Bebas Neue, sans-serif;
  @media (max-width: 991px) {
    font-size: 34px;
  }
`;

const Subtitle = styled.div`
  text-align: center;
  font: 700 20px/24px Jost, sans-serif;
  color: #f90;
  margin: 10px 0 30px;
  @media (max-width: 991px) {
    font-size: 18px;
  }
`;

const CoursesContainer = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 991px) {
    align-items: stretch;
  }
`;

const CourseCard = styled.div<{ active?: boolean; featured?: boolean }>`
  background: ${({ active }) =>
    active
      ? 'linear-gradient(147.95deg, #001b4f -10.15%, #004ee4 114.6%), #0e4ed6'
      : 'linear-gradient(147.95deg, #001b4f -10.15%, #004ee4 114.6%), #2b3a6e'};
  border: 1px solid #909090;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  width: calc(30% - 20px);
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
  ${({ featured }) =>
    featured &&
    `
    border: 2px solid #ff9900;
    box-shadow: 0 0 10px rgba(255, 153, 0, 0.5);
  `}
  opacity: ${({ active }) => (active ? 1 : 0.8)};
  @media (max-width: 991px) {
    width: 100%;
    min-height: auto;
    padding: 15px;
  }
`;

const CourseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const CourseTitle = styled.div`
  margin-top: 20px;
  font: 700 20px/24px Jost, sans-serif;
  @media (max-width: 991px) {
    margin-top: 10px;
    font-size: 18px;
  }
`;

const CourseSubtitle = styled.div`
  margin-top: 12px;
  font: 400 14px/18px Jost, sans-serif;
  color: #fff;
`;

const Column2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const Discount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  background-color: #f90;
  border-radius: 908.48px 0 0 908.48px;
  color: #fff;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 70px;
  font: 700 18px/100% Jost, sans-serif;
  @media (max-width: 991px) {
    height: 60px;
    width: 60px;
    font-size: 16px;
  }
`;

const CourseContentTitle = styled.div`
  margin: 10px 0;
  font: 600 16px/20px Jost, sans-serif;
`;

const CourseContent = styled.div`
  margin: 10px 0;
  font: 400 14px/18px Jost, sans-serif;
  min-height: 150px;
`;

const CourseItemsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const CourseItem = styled.li`
  margin-bottom: 8px;
  line-height: 1.4;
  position: relative;
  padding-left: 20px;
  font: 400 14px/20px Jost, sans-serif;

  &:before {
    content: '•';
    position: absolute;
    left: 0;
    color: #ff9900;
    font-weight: bold;
  }
`;

const PlaceholderContent = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font: 400 14px/18px Jost, sans-serif;
`;

const IncomeContainer = styled.div`
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 80px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-top: 10px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const IncomeTitle = styled.div`
  font: 600 16px/20px Jost, sans-serif;
`;

const Income = styled.div`
  font: 600 16px/20px Jost, sans-serif;
`;

const InfinitySymbol = styled.span`
  font-size: 34px;
  vertical-align: top;
  font-weight: 300;
`;

const DiscountNotice = styled.div`
  align-self: center;
  margin: 20px 0 0;
  font: 400 14px/18px Jost, sans-serif;
  color: #f90;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Price = styled.div`
  font: 600 16px/20px Jost, sans-serif;
  color: #ffffff;
`;

const OldPrice = styled.span`
  text-decoration: line-through;
  margin-left: 5px;
  color: rgba(255, 255, 255, 0.7);
`;

const InstallmentNotice = styled.div`
  font: 400 14px/18px Jost, sans-serif;
  color: #f90;
  margin-top: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const BuyButton = styled.div`
  align-items: center;
  background: #ff9900;
  border-radius: 140px;
  color: #ffffff;
  display: flex;
  font: 400 16px/20px Jost, sans-serif;
  padding: 10px 20px;
  cursor: pointer;
  justify-content: center;
`;

const NotifyButton = styled.button<{ active?: boolean }>`
  align-items: center;
  background: #ff9900;
  border-radius: 140px;
  color: #ffffff;
  display: flex;
  font: 400 16px/20px Jost, sans-serif;
  padding: 10px 80px;
  margin: 25px;
  cursor: pointer;
  justify-content: center;
  border: none;
  opacity: 1;
`;

const NotifyText = styled.div`
  font: 400 14px/18px Jost, sans-serif;
  color: #f90;
  margin-top: 10px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
`;

const ModalContent = styled.div`
  background: #001b4f;
  color: #fff;
  padding: 20px;
  border-radius: 24px;
  width: 80%;
  max-width: 600px;
  max-height: 80vh; 
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto; 
`;

const ModalClose = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

const ModalDescription = styled.p`
  font: 400 14px/18px Jost, sans-serif;
  line-height: 1.5;
  text-align: center;
`;

const InputField = styled.div`
  margin-bottom: 20px;
  label {
    display: block;
    margin-bottom: 5px;
  }
  input, select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
`;

const ErrorText = styled.p`
  color: #d32f2f;
`;

const PurchaseButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  background-color: #ff9900;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font: 400 16px/20px Jost, sans-serif;
  &:hover {
    background-color: #ff8c00;
  }
  &:disabled {
    background-color: #757575;
    cursor: not-allowed;
  }
`;

const CardElementWrapper = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 4px;
`;

const MotivationalText = styled.p`
  font-size: 18px;
  line-height: 1.8;
  color: #ff9900;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
`;

const PaymentMethodNotice = styled.div`
  font: 400 14px/18px Jost, sans-serif;
  color: #f90;
  margin-top: 10px;
  text-align: center;
`;

const TermsNotice = styled.div`
  font: 400 14px/18px Jost, sans-serif;
  color: #f90;
  margin-top: 20px;
  text-align: center;

  a {
    color: #f90;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

export default CourseSection;
