// src/components/Layout/Sidebar.tsx
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Sidebar: React.FC = () => {
    return (
        <SidebarContainer>
            <Logo>AMAZON FREEDOM</Logo>
            <NavMenu>
                <MenuItem>
                    <StyledNavLink to="/cabinet">Мои курсы</StyledNavLink>
                </MenuItem>
                <MenuItem>
                    <StyledNavLink to="/cabinet/price-analyzer">Анализатор прайс-листов</StyledNavLink>
                </MenuItem>
                <MenuItem>
                    <StyledNavLink to="/cabinet/payment-methods">Методы оплаты</StyledNavLink>
                </MenuItem>
            </NavMenu>
        </SidebarContainer>
    );
};

const SidebarContainer = styled.div`
    width: 240px;
    background-color: #001b4f;
    display: flex;
    flex-direction: column;
    padding: 20px;
`;

const Logo = styled.div`
    font-size: 24px;
    color: #fff;
    margin-bottom: 40px;
    font-weight: bold;
`;

const NavMenu = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const MenuItem = styled.li`
    margin-bottom: 20px;
`;

const StyledNavLink = styled(NavLink)`
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    &:hover {
        text-decoration: underline;
    }
    &.active {
        font-weight: 700;
        text-decoration: underline;
    }
`;

export default Sidebar;
