import React from 'react';
import styled from 'styled-components';
import Julia from '../assets/julia.png';
import AmazonStats from '../assets/amazonstats.png';

const CourseAuthor: React.FC = () => {
    return (
        <DivMain id="about">
            <Container>
                <InnerContainer>
                    <ContentContainer>
                        <Column>
                            <AuthorInfo>
                                <AuthorTitle>Автор курса</AuthorTitle>
                                <AuthorName>Привет, меня зовут Юлия и я помогу тебе выйти на Амазон в США</AuthorName>
                                <AuthorDescription>
                                    Мой путь на Amazon не был легким – я столкнулась с множеством трудностей и совершила множество ошибок. В своих курсах я делюсь уникальными тонкостями и лайфхаками, которых нет в бесплатных источниках.
                                </AuthorDescription>
                                <ExperienceContainer>
                                    <ExperienceIcon>
                                        <ExperienceImage
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1db66fd3c8e8ba2e12d29975c7d8da7205a757a12e15eef8282814b33d497564?apiKey=35bf81d825094f66b687d157fe963e7b&"
                                        />
                                    </ExperienceIcon>
                                    <ExperienceText>Путь от наемного работника до владельца бизнеса на Амазон</ExperienceText>
                                </ExperienceContainer>
                                <ExperienceContainer>
                                    <ExperienceIcon>
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/27567c941a2aa9a043dca986553fd9415fd2d5cbdfdef115d708b06659fa607a?apiKey=35bf81d825094f66b687d157fe963e7b&"
                                        />
                                    </ExperienceIcon>
                                    <ExperienceText>Помогаю мировым брендам реализовывать стратегии запуска на Амазоне</ExperienceText>
                                </ExperienceContainer>
                                <ExperienceContainer>
                                    <ExperienceIcon>
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d272ff6d64d8c5150a0b3463a2b29ec1afeac443c185e28ab21ef48b4c356bb8?apiKey=35bf81d825094f66b687d157fe963e7b&"
                                        />
                                    </ExperienceIcon>
                                    <ExperienceText>Зарабатываю на продажах по модели онлайн арбитраж</ExperienceText>
                                </ExperienceContainer>
                            </AuthorInfo>
                        </Column>
                        <Column2>
                            <ImageContainer>
                                <CourseImages>
                                    <MainImage
                                        loading="lazy"
                                        src={AmazonStats}
                                    />
                                    <SecondaryImage
                                        loading="lazy"
                                        src={Julia}
                                    />
                                </CourseImages>
                            </ImageContainer>
                        </Column2>
                    </ContentContainer>
                </InnerContainer>
            </Container>
        </DivMain>
    );
};

const DivMain = styled.div`
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
`;

const Container = styled.div`
    justify-content: flex-end;
    align-items: center;
    border-radius: 24px;
    background: linear-gradient(160deg, #0b172e -0.66%, #2b4a87 176.61%),
        linear-gradient(148deg, #001b4f -10.15%, #004ee4 114.6%),
        linear-gradient(160deg, #0b172e -0.66%, #2b4a87 176.61%);
    z-index: 0;
    display: flex;
    margin: 50px;
    margin-top: -200px;
    max-width: 100%;
    flex-direction: column;
    padding: 40px 30px 0;
    @media (max-width: 991px) {
        margin: 20px; 
        margin-top: 50px;
    }
`;

const InnerContainer = styled.div`
    margin: 20px;
    width: 100%;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const ContentContainer = styled.div`
    gap: 60px;
    display: flex;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 44%;
    margin-left: 0px;
    @media (max-width: 991px) {
        width: 100%;
    }
`;

const AuthorInfo = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 20px;
    }
`;

const AuthorTitle = styled.div`
    font-feature-settings: "clig" off, "liga" off;
    border-radius: 25px;
    border-color: rgba(255, 153, 0, 1);
    border-style: solid;
    border-width: 1px;
    align-self: start;
    color: #f90;
    justify-content: center;
    padding: 6px 12px;
    font: 400 18px/130% Jost, sans-serif;
`;

const AuthorName = styled.div`
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    margin-top: 24px;
    font: 700 48px/52px Bebas Neue, sans-serif;
    @media (max-width: 991px) {
        max-width: 100%;
        line-height: 48px;
    }
`;

const AuthorDescription = styled.div`
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    margin-top: 32px;
    font: 400 16px/20px Inter, sans-serif;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const ExperienceContainer = styled.div`
    border-radius: 24px;
    border-color: rgba(255, 153, 0, 1);
    border-style: solid;
    border-width: 1px;
    display: flex;
    margin-top: 20px;
    gap: 20px;
    align-items: center; 
    padding: 20px;
    @media (max-width: 991px) {
        flex-wrap: nowrap; 
        padding: 20px;
    }
`;

const ExperienceIcon = styled.div`
    align-items: center;
    border-radius: 88px;
    background-color: rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: center;
    width: 64px;
    height: 64px;
    padding: 13px;
`;

const ExperienceImage = styled.img`
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 38px;
`;

const ExperienceText = styled.div`
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    margin: auto 0;
    font: 400 16px/20px Inter, sans-serif;
    flex-grow: 1; 
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    @media (max-width: 991px) {
        width: 100%;
    }
`;

const ImageContainer = styled.div`
    border-radius: 24px 24px 24px 24px;
    background: linear-gradient(116deg, #f90 5.3%, #ffd494 94.16%);
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;
    position: relative;
    @media (max-width: 991px) {
        max-width: 100%;
        margin-top: 20px;
        flex-wrap: wrap;
    }
`;

const CourseImages = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    gap: 20px;
    position: relative;
    @media (max-width: 991px) {
        margin-top: 20px;
    }
`;

const MainImage = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60%;
    object-fit: cover;
    object-position: center;
    @media (max-width: 1388px) {
        display: none;
    }
`;

const SecondaryImage = styled.img`
    position: relative;
    width: 100%;
    object-fit: cover;
    object-position: center;
    @media (max-width: 1477px) {
        max-width: 90%;
    }
    @media (max-width: 1388px) {
        left: 30px;
    }
`;

export default CourseAuthor;
